<template>
  <v-navigation-drawer
    v-model="active"
    temporary
    class="navigation-drawer-main bg-tertiary"
  >
    <div class="pt-5 pb-7 pl-8 pr-6">
      <div class="d-flex justify-space-between">
        <v-icon @click="active = false">mdi-close</v-icon>
        <img
          src="~/assets/logos/Logo_Lustucru_Selection.png"
          height="48"
          width="48"
        />
      </div>
      <div class="pr-2">
        <div>
          <h5 class="primary-link">
            <nuxt-link
              class="link__a"
              to="/"
              @click="active = false"
            >
              Accueil
            </nuxt-link>
          </h5>
          <v-divider
            color="secondary"
            class="border-opacity-100"
          />
          <h5 class="primary-link">
            <nuxt-link
              class="link__a"
              :to="localePath('game-2')"
              @click="active = false"
            >
              Le Jeu
            </nuxt-link>
          </h5>
          <v-divider
            color="secondary"
            class="border-opacity-100"
          />
          <h5 class="primary-link">
            <nuxt-link
              class="link__a"
              :to="localePath('shop')"
              @click="active = false"
            >
              La Grande Boutique
            </nuxt-link>
          </h5>
          <v-divider
            color="secondary"
            class="border-opacity-100"
          />
          <h5 class="primary-link">
            <nuxt-link
              class="link__a"
              :to="localePath('how-to-participate')"
              @click="active = false"
            >
              Comment participer ?
            </nuxt-link>
          </h5>
          <v-divider
            color="secondary"
            class="border-opacity-100"
          />
          <h5 class="primary-link">
            <nuxt-link
              class="link__a"
              :to="localePath('eligible-products')"
              @click="active = false"
            >
              Produits éligibles
            </nuxt-link>
          </h5>
        </div>

        <v-divider
          color="secondary"
          class="border-opacity-100 mt-10"
        />

        <div class="py-3">
          <div class="social-links">
            <nuxt-link
              href="https://www.facebook.com/page.lustucru.selection/"
              target="_blank"
            >
              <IconFBSvg
                color="secondary"
                :width="40"
                class="mr-3"
              />
            </nuxt-link>
            <nuxt-link
              href="https://www.instagram.com/trucetmuche_lustucruselection/"
              target="_blank"
            >
              <IconInstaSvg
                color="secondary"
                :width="40"
              />
            </nuxt-link>
          </div>

          <div
            v-for="link in links"
            :key="link.text"
            class="secondary-link"
          >
            <nuxt-link
              v-if="link.to"
              class="link__a"
              :to="link.to"
              @click="active = false"
            >
              {{ link.text }}
            </nuxt-link>
            <nuxt-link
              v-else-if="link.href"
              class="link__a"
              :href="link.href"
              :target="link.target"
            >
              {{ link.text }}
            </nuxt-link>
          </div>
        </div>

        <v-divider
          color="secondary"
          class="border-opacity-100 mb-8"
        />

        <h5
          v-if="auth.isLogged"
          class="primary-link"
        >
          <nuxt-link
            href="#"
            class="link__a d-flex align-center"
            @click="logoutAndCloseDrawer"
          >
            <IconPowerSvg
              color="secondary"
              :height="20"
              class="mx-2"
            />
            Me déconnecter
          </nuxt-link>
        </h5>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script setup lang="ts">
const localePath = useLocalePath()

import { PRIVACY_POLICY_URL, GAME_2_REGULATIONS_URL } from '@/utils/constants'

const active = defineModel({
  type: Boolean,
})
const auth = useStoreAuth()

async function logoutAndCloseDrawer() {
  auth.logout().finally(() => {
    active.value = false
  })
}

const links = [
  {
    text: 'Contact',
    to: localePath('contact'),
  },
  {
    text: 'FAQ',
    to: localePath('faq'),
  },
  {
    text: 'Charte de confidentialités',
    href: PRIVACY_POLICY_URL,
    target: '_blank',
  },
  {
    text: 'Mentions légales',
    to: localePath('legal-notices'),
  },
  {
    text: 'Conditions de la Boutique',
    to: localePath('shop-conditions'),
  },
  {
    text: 'Règlement du Jeu',
    href: GAME_2_REGULATIONS_URL,
    target: '_blank',
  },
]
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.navigation-drawer-main {
  z-index: $z-index-mobile-main-drawer !important;
  height: 100% !important;
  top: 0 !important;
  width: 280px !important;
}

.primary-link {
  margin-top: 15px;
  margin-bottom: 15px;
}
.secondary-link {
  margin-top: 10px;
  margin-bottom: 10px;
}

.link__a {
  text-decoration: none;
  color: $color-secondary;
}

.social-links {
  float: right;
}
</style>
