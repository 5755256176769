<template>
  <v-footer
    dark
    color="secondary"
    class="footer-desktop"
  >
    <div class="footer__banner" />
    <div class="footer__top">
      <ul class="footer__links">
        <li
          v-for="link in links"
          :key="link.to"
          class="footer__link"
        >
          <nuxt-link
            v-if="link.to"
            :to="link.to"
            class="footer__link__a"
          >
            {{ link.text }}
          </nuxt-link>
          <nuxt-link
            v-else-if="link.href"
            :href="link.href"
            :target="link.target"
            class="footer__link__a"
          >
            {{ link.text }}
          </nuxt-link>
        </li>

        <li class="footer__link">
          <a
            href="#"
            class="footer__link__a"
            @click.prevent="layout.openDialogContact"
          >
            Contact
          </a>
        </li>
      </ul>
      <div class="social-links d-flex">
        <nuxt-link
          href="https://www.facebook.com/page.lustucru.selection/"
          target="_blank"
          class="d-flex"
        >
          <IconFBSvg
            color="white"
            :width="40"
            class="mr-3"
          />
        </nuxt-link>
        <nuxt-link
          href="https://www.instagram.com/trucetmuche_lustucruselection/"
          target="_blank"
          class="d-flex"
        >
          <IconInstaSvg
            color="white"
            :width="40"
          />
        </nuxt-link>
      </div>
    </div>
    <div class="footer__bottom">
      <div class="text-white mb-4">
        <strong>
          Jeu avec obligation d'achat valable du 12/08/2024 au 29/09/2024.
          <br />
          Offre Boutique avec obligation d'achat valable du 18/03/2024 au
          31/12/2024.
        </strong>
      </div>
      <div class="text-tertiary">
        Pour votre santé, mangez au moins cinq fruits et légumes par jour.
        <a
          href="https://www.mangerbouger.fr"
          target="_blank"
        >
          www.mangerbouger.fr
        </a>
      </div>
    </div>
  </v-footer>
</template>

<script setup lang="ts">
const localePath = useLocalePath()

import { PRIVACY_POLICY_URL, GAME_2_REGULATIONS_URL } from '@/utils/constants'

const layout = useStoreLayout()
const links = [
  {
    text: 'FAQ',
    to: localePath('faq'),
  },
  {
    text: 'Charte de confidentialités',
    href: PRIVACY_POLICY_URL,
    target: '_blank',
  },
  {
    text: 'Mentions légales',
    to: localePath('legal-notices'),
  },
  {
    text: 'Conditions de la Boutique',
    to: localePath('shop-conditions'),
  },
  {
    text: 'Règlement du Jeu',
    href: GAME_2_REGULATIONS_URL,
    target: '_blank',
  },
]
</script>

<style lang="scss" scoped>
$background-image-width: 1440px;
$background-image-height: 40px;

.footer-desktop {
  flex-grow: 0;
  padding: 0;
  flex-direction: column;
}
.footer__banner {
  width: 100%;
  height: calc($background-image-height / 2);
  background-image: url('~/assets/images/background-footer.png');
  background-position: left top;
  background-size: calc($background-image-width / 2)
    calc($background-image-height / 2);
  background-repeat: repeat-x;
}
.footer__top {
  font-size: 14px;
  line-height: 18px;
  margin: 30px 0;
  position: relative;
}

.footer__bottom {
  text-align: center;

  font-size: 12px;
  line-height: 16px;

  margin: 0 0 30px 0;

  a {
    color: inherit;
  }
}

.footer__links {
  list-style: none;
}
.footer__link {
  display: inline-block;
}
.footer__link + .footer__link {
  margin-left: 30px;
}
.footer__link__a {
  text-decoration: none;
  color: white;
}

.social-links {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -120px;
}
</style>
