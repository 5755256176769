<template>
  <v-app-bar
    dark
    color="secondary"
    :extension-height="extensionHeight"
    :class="layout.appBarCssClasses"
  >
    <!-- MOBILE -->
    <v-app-bar-nav-icon
      variant="text"
      class="hidden-sm-and-up"
      @click.stop="drawer = !drawer"
    />
    <v-spacer class="hidden-sm-and-up" />
    <nuxt-link
      v-if="auth.isLogged"
      to="/"
      class="hidden-sm-and-up mr-5"
    >
      <v-img
        src="~/assets/logos/Logo_Lustucru_Selection.png"
        :height="48"
        :width="48"
      />
    </nuxt-link>
    <AppButtonRoundTertiary
      v-else
      :to="localePath('auth')"
      class="hidden-sm-and-up mr-5"
    >
      connexion
    </AppButtonRoundTertiary>

    <!-- DESKTOP -->
    <v-container class="hidden-xs pa-0 d-sm-flex align-center">
      <div class="img">
        <nuxt-link
          class="link"
          to="/"
        >
          <v-img
            src="~/assets/logos/Logo_Lustucru_Selection.png"
            width="60"
          />
        </nuxt-link>
      </div>

      <v-spacer />

      <nuxt-link
        v-for="link in links"
        :key="link.text"
        class="link"
        :to="link.to"
      >
        {{ link.text }}
      </nuxt-link>

      <v-spacer />

      <AppHeaderRight />
    </v-container>

    <template
      v-if="
        mounted &&
        (showBreadcrumbs ||
          game1.isUnfinished ||
          game2.isParticipationUncomplete)
      "
      #extension
    >
      <v-container
        fluid
        class="pa-0 ma-0"
      >
        <v-row
          v-if="showBreadcrumbs"
          class="pa-0 ma-0"
        >
          <v-col cols="12">
            <AppBreadcrumbs />
          </v-col>
        </v-row>
        <v-row
          v-if="game1.isUnfinished || game2.isParticipationUncomplete"
          class="pa-0 ma-0"
        >
          <v-col
            class="pa-0 ma-0"
            cols="12"
          >
            <div
              class="bg-quaternary text-white px-5 px-sm-0"
              :class="{
                'warning-participation': smAndUp,
                'warning-participations-mobile': mobile,
              }"
            >
              Vous n'avez pas fini de valider votre participation au Jeu !
            </div>
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-app-bar>
</template>

<script setup lang="ts">
const localePath = useLocalePath()
const { mobile, smAndUp } = useDisplay()

const layout = useStoreLayout()
const auth = useStoreAuth()

const drawer = defineModel({
  type: Boolean,
})

const { mounted } = useMounted()

const HEIGHT_BREADCRUMBS = 42
const HEIGHT_PARTICIPATION_UNCOMPLETE = 50

const links = [
  {
    text: 'Accueil',
    to: '/',
  },
  {
    text: 'Le Jeu',
    to: localePath('game-2'),
  },
  {
    text: 'La Grande Boutique',
    to: localePath('shop'),
  },
  {
    text: 'Comment participer ?',
    to: localePath('how-to-participate'),
  },
  {
    text: 'Produits éligibles',
    to: localePath('eligible-products'),
  },
]

const extensionHeight = computed(() => {
  if (!game1.isUnfinished && !game2.isParticipationUncomplete) {
    return HEIGHT_BREADCRUMBS
  }

  if (smAndUp.value) {
    return HEIGHT_BREADCRUMBS + HEIGHT_PARTICIPATION_UNCOMPLETE
  } else {
    return HEIGHT_PARTICIPATION_UNCOMPLETE
  }
})

const game1 = useStoreGame1()
const game2 = useStoreGame2()

const showBreadcrumbs = computed(
  () => smAndUp.value && layout.breadcrumbs.length > 0,
)
</script>

<style lang="scss" scoped>
@import '~/assets/styles/variables.scss';

.link {
  text-decoration: none;
  color: white;
  font-size: 15px;
  line-height: 16px;
  font-family: $font-family-baloo;
}
.link + .link {
  margin-left: 20px;
}

.warning-participation {
  height: 50px;
  margin: 0;
  display: flex;
  font-family: $font-family-baloo;
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  justify-content: center;
  align-items: center;
}

.warning-participations-mobile {
  position: sticky;
  height: 50px;
  margin: 0;
  display: flex;
  font-family: $font-family-baloo;
  font-size: 15px;
  font-weight: 400;
  line-height: 17px;
  text-align: left;
  justify-content: center;
  align-items: center;
}
</style>
